import Http from '@/services/Http'
import BaseService from '@/services/BaseService'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TarifarioMaritimoService = {

  getLcl (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tarifario/lcl/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }

}

export default TarifarioMaritimoService
